import '../styles/App.css';
import React from 'react';

function App() {
  return (
    <div className="App">
      <h1>En desarrollo</h1>
    </div>
  );
}

export default App;
